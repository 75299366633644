.login-header {
  display: flex;
  justify-content: center;
  margin: 2.5em auto;
}

.login-header img {
  /* filter: drop-shadow(2px 4px 6px black); */
  width: 10%;
  aspect-ratio: 16/9;
}

#login-form-id{
  position: relative;
  width: 100vw;
  height: 100vh;
}
.background_image{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.login_form_wrapper{
  height: 100%;
  width: 100%;
}
.login_form{
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_form h2{
  color: #042C92;
  font-family :'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  margin-bottom: 2rem;
}
.login_form_email_label, .login_form_password_label{
  color: #949494;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}

.form-group {
  margin-bottom: 1rem;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333333;
}
button[type="submit"]{
  width: 100%;
  background-color: #042C92;
  color: white;
  border-radius: 16px;
}

button[type="submit"]:hover{
  background-color: #1943ad;

}

input[type="email"],
input[type="password"],
#otp {
  width: 100%;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

button {
  display: block;
  /* width: 100%; */
  padding: 10px;
  background-color: #4285f4;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
}

.signup-link {
  text-align: center;
  margin-top: 20px;
}

.signup-link a {
  color: #4285f4;
  text-decoration: none;
}

.signup-link a:hover {
  text-decoration: underline;
}

.alert {
  padding: 10px;
  margin: 10px 0px;
  border-radius: 4px;
}

.alert-success {
  background-color: #dff0d8;
  color: #3c763d;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.alert-danger {
  background-color: #f2dede;
  color: #a94442;
}

.mfa {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
  flex-direction: column;
  align-items: center;
}
