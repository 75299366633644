#preview-campaign .address-tothe {
  background: #f3f7fa;
  padding: 10px;
  border-radius: 8px;
}

#preview-campaign .singnatures h2 {
  font-size: 60px;
  color: white;
  margin: 30px 12px;
}

#preview-campaign .singnatures h4 {
  font-size: 18px;
  margin: -30px 12px;
  font-weight: 400;
  font-family: "Staatliches", cursive;
}

#preview-campaign .address-tothe img {
  height: 100%;
  max-width: 100%;
}

#preview-campaign #sigh-the-petition {
  background: #9595d2;
  margin: 8px 0;
  border-radius: 10px;
}

#preview-campaign .march-sign p {
  color: #666;
  font-size: 14px;
  margin-top: 20px;
  line-height: 20px;
  margin-right: 10px;
}

#preview-campaign .march-sign {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

#preview-campaign .march-sign h4 a {
  font-size: 20px;
  padding: 5px 16px;
}

#preview-campaign .btn-pink {
  background: #ec2d8b;
  color: #fff;
  display: inline-block;
  font-family: "Staatliches", cursive;
  font-size: 18px;
}

#preview-campaign .btn-pink:hover {
  background: black;
  color: white;
}

#preview-campaign .urgent-action p a {
  color: #197ff4;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}

#preview-campaign .urgent-action {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#preview-campaign .march-sign {
  margin-bottom: 1rem;
}

#preview-campaign .singnatures h4 {
  margin-right: 40px;
}
@media (max-width: 1366px) {
  #preview-campaign .singnatures h4 {
    margin: -30px 0px;
  }

  #preview-campaign .singnatures h2 {
    margin: 30px 0px;
  }
}
@media (max-width: 1200px) {
  #preview-campaign .singnatures {
    /* padding: 15px 2px; */
  }
}
@media (max-width: 912px) {
  #preview-campaign .singnatures {
    padding: 0 10px;
  }
}

@media (max-width: 540px) {
  #preview-campaign .address-tothe img {
    width: 100%;
  }
  #preview-campaign #sigh-the-petition {
    background: #9595d2;
    margin: 8px 0;
    border-radius: 10px;
  }
  #preview-campaign .singnatures {
    text-align: center;
    padding-bottom: 50px;
  }
}
