.progress-container {
  margin-top: 1rem;
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.progress-fill {
  height: 100%;
  background-color: #007bff;
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
}

.progress-text {
  font-size: 14px;
  color: #333;
  margin-top: 5px;
}
