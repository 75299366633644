.userSidebar {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 389px;
    background: #ffff;
    z-index: 100;
    transition: all 0.5s ease;
    overflow: scroll;
    padding: 20px;
}

.close-button {
    cursor: pointer;
  }

  .bxs-trash {
    cursor: pointer;
  }

  .userSidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
  }

  .resource-form-body > span{
    display: flex;
    align-items: center;
    justify-content: space-around;
    justify-content: flex-start;
  }

  .resource-form-body > span > label{
    margin: 0px;
    margin-left: .5em;
    min-width: 8em;
    }