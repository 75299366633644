.issue-page-single-container {
  max-width: 1000px;
}

.sub_text_single {
  margin-top: 4px;
  margin-left: 14px;
  font-size: 15px;
  color: #666e77;
}

.sub-heading-text-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.comment-box {
  padding: 0px;
  background-color: #ffffff;
  border-radius: 6px;
  border: 2px solid #e1e4e8;
  box-shadow: none;
  width: 100%;
}

.comment-box-row {
  display: flex;
  justify-content: flex-start;
}

.comment-user-name {
  font-size: 16px;
  font-weight: 600;
  color: #24292e;
  background-color: #f6f8fa;
  border-bottom: 2px solid #e1e4e8;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.author-name-bubble {
  border: 1.5px solid #e1e4e8;
  font-size: 12px;
  color: #737a82;
}

.comment-textarea {
  height: 140px;
}

.closed-btn {
  background-color: #8250df;
  border-color: #8250df;
  color: #ffffff;
}

.open-btn {
  background-color: #1f883d;
  border-color: #1f883d;
  color: #ffffff;
}

.horizontal-line {
  padding: 2px;
  width: 800px;
}
