:root {
  font-size: calc(10px + 1vmin);
  --primary: #73f;
  --primary-2: #37f;
  --softGrey: #9a97a8;
  --softTint: rgba(0, 0, 0, 0.2);
  --bounce: 0.3s cubic-bezier(0.3, -0.1, 0.5, 1.4);
  --ease: 0.2s cubic-bezier(0.3, 0.1, 0.3, 1);
  --space: 1rem;
}
#mfa input[type="checkbox"] {
  position: absolute;
  opacity: 0.001;
}
#mfa form {
  /* --size: 3rem; */
  width: fit-content;
  padding-left: var(--space);
  position: relative;
  display: flex;
  overflow: hidden;
  box-shadow: 0 1em 2em var(--softTint);
  transition: var(--bounce);
}
#mfa form:focus-within {
  transform: scale(0.94);
  box-shadow: 0 1em 1em var(--softTint);
}
#mfa form input[type="text"] {
  appearance: none;
  -webkit-appearance: none;
  height: 100%;
  width: 25px;
  /* height: calc(var(--size) * 1.4); */
  font-size: calc(var(--size) * 0.7);
  text-align: center;
  border: 0.12em solid var(--softTint);
  border-radius: calc(var(--size) * 0.2);
  outline: none;
  margin: var(--space);
  margin-left: 0;
  transition: var(--ease);
}
#mfa form input[type="text"]::-webkit-outer-spin-button,
#mfa form input[type="text"]::-webkit-inner-spin-button {
  display: none;
}
#mfa form input[type="text"]:not(:placeholder-shown) {
  border-color: var(--primary);
}
#mfa form .submit {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  outline: none;
  display: grid;
  place-items: center;
  width: calc(var(--size) + var(--space) * 2);
  padding: 1em;
  cursor: pointer;
  background-color: var(--softTint);
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cpath fill='white' d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z'/%3E%3C/svg%3E"); */
  background-position: 50% 50%;
  background-size: 30%;
  background-repeat: no-repeat;
  transition: var(--ease);
  animation: tension 0.36s cubic-bezier(0.2, 0.4, 0.5, 1) paused alternate
    infinite;
}
#mfa form label {
  margin: 0px;
}

#mfa form .submit:focus {
  background-color: var(--primary);
  animation-play-state: running;
}
#mfa form .indicator {
  --i: 0;
  position: absolute;
  bottom: 0;
  width: var(--size);
  height: 0.5em;
  border-radius: 0.5em 0.5em 0 0;
  background: var(--primary);
  transform: translateX(calc((var(--size) + var(--space)) * (var(--i) - 1)));
  transition: var(--bounce);
}

@keyframes tension {
  to {
    background-position-x: 60%;
  }
}
