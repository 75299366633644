h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-family: "Staatliches", cursive;
  /* margin: 0 0 20px 0; */
  color: #111111;
}
.about-us-dashboard .container {
  max-width: 1200px;
  background-color: initial;
}
.about-caption-top {
  margin: 40px 0;
  text-align: justify;
}

.about-caption-top span {
  font-weight: 600;
}

.about-caption-top p {
  line-height: 24px;
  color: #666;
  text-align: justify;
}
.objectiw .specific h4 {
  text-align: center;
  font-size: 26px;
  margin: 50px 0 20px 0;
}

.objectiw .card {
  background: #e1e621;
  padding: 20px;
  line-height: 20px;
  /* height: 200px; */
  margin: auto;
  margin-bottom: 20px;
}

.objectiw .card h4 {
  font-size: 18px;
}
.about-us-dashboard .card {
  width: 300px;
  margin: 0.5em;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.about-us-dashboard .card-body h4 a:hover,
.about-us-dashboard .card-body h3 a:hover {
  color: #1c93f4;
}
.card-body p {
  line-height: 18px;
  font-size: 14px;
}

.card-body h3 {
  line-height: 22px;
}

.card-box-h:hover .hover-effect:after {
  opacity: 1;
}

.about-us-dashboard .edit-btn button {
  background-color: #495057;
}

.about-us-dashboard .dis-edit-btn button {
  background-color: #babfc5;
}

.about-us-dashboard .actions {
  width: 100%;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 6px;
}

.about-us-dashboard .actions .lx-btn {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: white;
}

.about-us-dashboard .actions .lx-btn#cancel,
.about-us-dashboard .actions .lx-btn.cancel {
  background-color: #f55;
}

.about-us-dashboard .actions .lx-btn#clear,
.about-us-dashboard .actions .lx-btn.clear {
  color: black;
  background-color: white;
}

.about-us-dashboard .actions .lx-btn#save,
.about-us-dashboard .actions .lx-btn.save {
  background-color: #0072ce;
}

.about-us-dashboard .something-do {
  background: #005ab8;
  text-align: center;
  padding: 40px;
  margin: 40px 0;
}

.about-us-dashboard .amazing-do h2 {
  font-size: 58px;
  color: white;
  line-height: 60px;
}

.about-us-dashboard .amazing-do h2 span {
  font-size: 44px;
  display: block;
}

.amazing-do a {
  font-size: 30px;
  width: 230px;
  padding: 8px 0;
}

.nav-item button.active {
  background: #3863A0!important;
  color: #fff !important;
  box-shadow: 2px 2px 10px rgb(56, 56, 56) !important;
  border: none !important;
}

.nav-tabs {
  border-bottom: 1px solid rgb(57, 72, 103);
}
