:root {
  --main-bg: #ffffff;
  --second-bg: #fafafb;
  --txt-color: #455560;
  --txt-white: #fff;
  --main-color: #349eff;
  --second-color: #62b4ff;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.status-card {
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  background-color: #eef2ff;
  /* border-radius: var(--border-radius); */
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 0.5s ease 0s;
  width: auto;
  min-height: inherit;
  box-shadow: 0px 0px 2px 0px #0072ce;
}

.status-card__info {
  flex-grow: 1;
  text-align: center;
  z-index: 1;
  text-transform: capitalize;
}

.status-card::before {
  content: "";
  width: 100%;
  padding-top: 100%;
  border-radius: 50%;
  background-image: linear-gradient(
    to top right,
    var(--main-color),
    var(--second-color)
  );
  position: absolute;
  left: -50%;
  top: 0;
  transform: scale(0);
  transition: transform 0.8s ease 0s;
}

.status-card:hover::before {
  transform: scale(3);
}

.status-card:hover {
  color: var(--txt-white);
  box-shadow: 6px 6px 7px 0px #0072ce;
}
