.issue-page .container {
  max-width: 1000px;
}
.open {
  fill: #1a7f37;
}
.closed {
  fill: #8250df;
}
.issue-heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.issue-page h4 {
  margin-bottom: 0px;
  font-size: 18px;
  margin-left: 10px;
  color: #24292e;
}
.issue_row {
  border: 1px solid #ccc;
  border-radius: 5px;
}
.sub_text {
  margin-left: 28px;
  margin-top: 6px;
  font-size: 12px;
  color: #666e77;
}

.issue_row:hover {
  background-color: #f6f8fa;
  cursor: pointer;
}

.link-style {
  text-decoration: none;
}

.search-box input[type="text"] {
  width: 100%;
  padding: 10px;
  padding-left: 25px;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.subnav-search-input {
  color: #727c87;
}

.search-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.subnav-search-icon {
  z-index: 100;
  position: relative;
  top: 15px;
  left: -30px;
  display: block;
  color: var(--color-fg-muted);
  text-align: center;
  pointer-events: none;
}
.btn-flex {
  display: flex;
  text-align: center;
  justify-content: center;
}

.form-component {
  width: 600px;
}

.form-div-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.issue-page .container {
  width: initial;
}
