/* .toast-container {
    opacity: 0;
    animation: fade-in 1s linear;
} */

.toast::after {
    position: absolute;
    width: var(--bs-toast-max-width);
    content: "";
    height: 5px;
    background-color: black;
    transform: scaleX(0) translateY(-5px);
    transform-origin: left;
    border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
    box-shadow: var(--bs-toast-box-shadow);
    border-radius: var(--bs-toast-border-radius);
    animation: progress 5s .3s linear;
}

@keyframes fade-in {
    5% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes progress {
    to {
        transform: scaleX(1);
    }
}