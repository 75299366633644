#preview .dolor-blog {
  display: flex;
  gap: 10px;
}

#preview a {
  text-decoration: none;
  cursor: pointer;
}

#preview .dolor-blog p {
  background: #dadada;
  padding: 5px 20px;
  border-radius: 20px;
  font-size: 14px;
}

#preview .dolor-blog p a {
  color: #666666;
}

#preview .dolor-blog p:hover {
  background: #00aee5;
}

#preview .dolor-blog p:hover a {
  color: white;
}

#preview .every-one p {
  line-height: 20px;
  font-size: 14px;
  color: #666666;
  text-align: justify;
}
#preview .smile-photo {
  display: flex;
}
#preview .smile-photo img {
  width: auto;
  max-height: 400px;
}

#preview .every-one h2 {
  font-size: 36px;
  padding-top: 20px;
  font-weight: 400;
  font-family: "Staatliches", cursive;
}

#preview .ceo-founder {
  border: 15px solid #9595d2;
  padding: 25px;
}

#preview .fec-twi-lin i {
  background: #00ace2;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  color: white;
  border-radius: 50%;
  margin: 10px 5px;
}
#preview .john-doe {
  margin-bottom: -20px;
}

#preview .john-doe h4 {
  font-size: 36px;
}

#preview .john-doe h4 a {
  color: black;
  font-weight: 400;
  font-family: "Staatliches", cursive;
}

#preview .john-doe h4 span {
  display: flex;
  font-size: 16px;
  color: #6cd8fa;
}

#preview .john-doe p {
  font-size: 16px;
  line-height: 20px;
  text-align: justify;
}
#preview img {
  max-width: 100%;
  margin: auto;
}

#preview a {
  color: #0d6efd;
}

#preview .posted-date {
  color: #999;
  font-size: 14px;
  margin-bottom: 10px;
}

#preview .posted-date span {
  padding: 0px 15px;
  font-size: 14px;
  color: #999;
  line-height: 20px;
}

#preview .right-event-detail {
  background: #ebf1f7;
  padding: 15px;
}

#preview .event-date-r {
  display: flex;
  gap: 20px;
}

#preview .event-date-r p {
  font-size: 14px;
  margin: 0;
  padding-bottom: 10px;
}

#preview .event-date-r p {
  font-weight: bold;
  color: #0d6efd;
}

#preview .event-date-r p span {
  display: flex;
  line-height: 18px;
  font-weight: normal;
  color: #666;
}

#preview .may-dum {
  background-color: white;
  padding: 5px;
  margin-bottom: 15px;
  box-shadow: 0px 1px 1px #b5b5b5;
}

#preview .organizer-event {
  padding: 15px;
}
#preview .organizer-event h4 {
  margin: 0;
  padding-bottom: 10px;
  font-weight: 400;
  font-family: "Staatliches", cursive;
}

#preview .organizer-event p {
  margin: 0;
  line-height: 18px;
  padding-bottom: 8px;
  font-size: 14px;
}

#preview .organizer-event p span {
  font-weight: bold;
}
#preview .organizer-event p a {
  color: #666;
}

#preview .eng-btn {
  display: flex;
}

#preview .eng-btn h5 {
  background: #f8f8f8;
  border: 1px solid;
  font-size: 14px;
  background: #f8f8f8;
  border: 1px solid #d3cccc;
  margin: 10px 5px 15px 0px;
  border-radius: 10px;
  width: 60px;
  text-align: center;
}
#preview h1,
#preview h2 {
  line-height: 48px;
  font-weight: 400;
  font-family: "Staatliches", cursive;
}

#preview h3,
#preview h4,
#preview h5 {
  line-height: 30px;
  font-weight: 400;
  font-family: "Staatliches", cursive;
}
