:root {
  --primary_color: #3f87e5;
  --hover_color_pagination: #629dea;
  --hover_color_tr: #e9f1fc;
}

body {
  background-color: white;
  font-family: "Staatliches", cursive;
  margin: 0;
  padding: 0;
}
.fa-1x {
  font-size: 1.5em;
  cursor: pointer;
}
.alert-success {
  margin-left: 20%;
  margin-right: 20%;
  position: fixed;
  z-index: 20;
  top: 1em;
}

#popover-contained {
  min-width: 500px;
  max-height: 650px;
  overflow-x: clip;
  overflow-y: auto;
}

.rbc-calendar {
  height: 100vh;
  margin-bottom: 3em;
}

.table-filters {
  gap: 1em;
}
.dropdown-menu.show {
  max-height: 13em;
  overflow-y: scroll;
}
.loader-overlay {
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
}
.user-section {
  position: relative;
  background: white;
  height: max-content;
  /* left: 78px; */
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.user-content {
  margin-top: 6em;
  width: inherit;
}

.bxs-edit {
  cursor: pointer;
}

.card-body {
  line-height: 18px;
  font-size: 14px;
}
@media (max-width: 950px) {
  .user-section {
    width: 100%;
    justify-content:unset;
    padding: 0px 1rem;
  }
  .table-responsive{
    max-width: calc(100%-270px);
  }
}
.ck.ck-editor__editable_inline{
  min-height: 150px;
}