#course-preview-section .smile-photo {
  display: flex;
}
#course-preview-section .smile-photo img {
  width: auto;
  max-height: 400px;
}
#course-preview-section .women-skills p {
  margin: 0;
}
#course-preview-section .women-skills p a {
  color: #666;
}
#course-preview-section .women-skills span {
  font-weight: bold;
  color: #000;
}
#course-preview-section .course-join a {
  font-size: 20px;
  margin: 20px 0;
  padding: 8px 30px;
}

#course-preview-section .marathon-event h5 {
  font-size: 18px;
}

#course-preview-section .marathon-event {
  font-size: 24px;
  margin-top: 20px;
}
#course-preview-section .marathon-event p,
#course-preview-section .marathon-event ul li {
  font-size: 14px;
  line-height: 18px;
  color: #666;
}

#course-preview-section .marathon-event ul li {
  font-size: 14px;
  line-height: 24px;
  color: black;
}

#course-preview-section .marathon-event ul li i {
  color: #0059dc;
}

#course-preview-section .btn-pink {
  background: #ec2d8b;
  color: #fff;
  display: inline-block;
  font-family: "Staatliches", cursive;
  font-size: 18px;
}

#course-preview-section .btn-pink:hover {
  background: black;
  color: white;
}
