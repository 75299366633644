/* Grid */
/* 
.image-container {
  height: fit-content !important;
} */

.react-photo-gallery--gallery > div:hover > .image-container:not(:hover) {
  opacity: 0.6;
}

.image-container > img {
  padding: 5px;
}

.image-container > p {
  opacity: 0;
  padding: 0px 20px 0px 20px;
  background: #333;
  color: white;
  bottom: -100%;
  height: max-content !important;
  position: absolute;
}

.image-container:hover > p {
  opacity: 1;
  bottom: 0%;
  /* margin-left: -50px;
    margin-bottom: -30px; */
  -webkit-transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
