.user_details{
    cursor: pointer;
}
.user_details:hover{
    background-color: rgb(239, 236, 236);
    text-shadow:1px 1px 10px white !important;
    text-decoration: underline;
}
.user_image_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.user_image{
    width: 150px;
    height: 150px;
}
.user_name{
    text-align: center;
    margin-bottom: 2rem;
}
.custom h5{
background-color: #3863a0;
color: white;
padding: 5px 10px;
text-align: center;
}
.personal_info_wrapper{
    margin-bottom: 1.5rem;
}
.personal_info{
    padding: 0px 20px
}
.cso_wrapper{
    margin-bottom: 1.5rem;
}
.cso{
    padding: 0px 20px;
    text-align: center;
}
.cso h6{
    margin: 0;
    text-align: center;
}