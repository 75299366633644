.settings-container {
}

.settings-container .lx-column {
  padding: 2rem;
  padding-top: 0px;
  padding-bottom: 0px;
}

.settings-container .lx-column .column-user-pic {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.settings-container .profile-pic {
  width: auto;
  max-width: 20rem;
  margin: 3rem 2rem;
  padding: 2rem;
  display: flex;
  flex-flow: wrap column;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  background-color: white;
}

.settings-container .profile-pic .pic-label {
  width: 100%;
  margin: 0 0 1rem 0;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 700;
}

.settings-container .profile-pic .pic {
  width: 16rem;
  height: 16rem;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.settings-container .profile-pic .pic .lx-btn {
  opacity: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  text-transform: none;
  font-size: 1rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
}

.settings-container .profile-pic .pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.settings-container .profile-pic .pic:focus .lx-btn,
.settings-container .profile-pic .pic:focus-within .lx-btn,
.settings-container .profile-pic .pic:hover .lx-btn {
  opacity: 1;
  display: flex;
}

.settings-container .profile-pic .pic-info {
  width: 100%;
  margin: 2rem 0 0 0;
  font-size: 0.9rem;
  text-align: center;
}

.settings-container form {
  width: auto;
  min-width: 15rem;
  max-width: 25rem;
  margin: 3rem 0 0 0;
}

.settings-container form .fieldset {
  width: 100%;
  margin: 2rem 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.settings-container form .fieldset label {
  width: 100%;
  /* margin: 0 0 1rem 0; */
  font-size: 1.2rem;
  font-weight: 700;
}

.settings-container form .fieldset .input-wrapper {
  width: 600%;
  display: flex;
  flex-flow: nowrap;
  align-items: stretch;
  justify-content: center;
}

.settings-container form .fieldset .input-wrapper .icon {
  width: fit-content;
  margin: 0;
  padding: 0.375rem 0.75rem;
  display: flex;
  align-items: center;
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 0.0625rem solid #ced4da;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  background-color: #e9ecef;
}

.settings-container form .fieldset .input-wrapper input,
.settings-container form .fieldset .input-wrapper textarea,
.settings-container form .fieldset .input-wrapper select {
  width: 100%;
  flex-grow: 1;
  padding: 0.375rem 0.75rem;
  display: block;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
  border: 0.0625rem solid #ced4da;
  border-left: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
}

.settings-container form .fieldset .input-wrapper:focus .icon,
.settings-container form .fieldset .input-wrapper:focus-within .icon,
.settings-container form .fieldset .input-wrapper:hover .icon {
  color: #0072ce;
}

.settings-container form .fieldset:first-child {
  margin-top: 0;
}

/* .settings-container form .fieldset:last-child {
  margin-bottom: 0;
} */

.settings-container form .actions {
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
}

.settings-container form .actions .lx-btn {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: white;
}

.settings-container form .actions .lx-btn#cancel,
.settings-container form .actions .lx-btn.cancel {
  background-color: #f55;
}

.settings-container form .actions .lx-btn#clear,
.settings-container form .actions .lx-btn.clear {
  color: black;
  background-color: white;
}

.settings-container form .actions .lx-btn#save,
.settings-container form .actions .lx-btn.save {
  background-color: #0072ce;
}

.settings-container .bs-md {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.settings-container .has-dflex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.settings-container .edit-btn button {
  background-color: #495057;
}

.settings-container .dis-edit-btn button {
  background-color: #babfc5;
}

.settings-container .row-heading {
  display: flex;
  justify-content: center;
}

.settings-container .input-wrapper-btn {
  display: flex;
  align-items: center;
  width: inherit;
}

@media screen and (max-width: 64rem) {
  .settings-container .profile-pic {
    max-width: 100%;
    margin: 0;
  }
}

@media screen and (max-width: 56.25rem) {
  .settings-container form {
    max-width: 100%;
    margin: 0;
  }
}
