.contactUs_wrapper{
    width: 100%;
    padding: 20px;
}
.contact_title{
    height: 320px;
    background-color: #005ab8;
    margin-top:60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
}
.contact_title h1{
    color: white;
}
.contact_cards_wrapper{
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
}
.cards{
    background-color: #f2f2f2;
    width: 100%;
    border-radius: 10px;
    padding:20px;
    border-bottom: 10px solid #e1e621;
    min-height: 360px;
    margin-bottom: 20px;
    position: relative;
}
.card_title{
    text-align: center;
}
.subtitle{
    margin-bottom: 15px;

}
.card_title h2, .subtitle h2{
    text-align: center;
    font-size: 24px;
    margin: 0px;
}
.card_title input{
    margin-bottom: 15px;
}
.cards h6{
    background-color:#9595d2;
    height: 3px;
    width: 50px;
    margin: auto auto 15px;
}
.card_content{
    text-align: left;
    margin-bottom: 5px;
    min-height: 235px;
}
.card_content p{
    font-size: 16px;
    margin:0px;
    line-height: 20px;
}
.address, .phone, .email, .link{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.add_wrapper{
    width: 100%;
    min-height: 425px;
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}
.add_card{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
}
.icon-size{
    font-size: 4rem;
    color:#005ab8 ;
}
.cards input{
    border:none;
    width: 100%;
    padding: 5px;
    text-align: center;
}
.cards input:focus{
    border: 1px solid #005ab8  ;
    outline: none
}
.saveEdit-btn{
    display: flex;
    justify-content: center;
}
.edit-btn{
    padding: 5px 27px;
    border-radius: 0px;
}
.save-btn{
    background-color: rgb(0, 161, 8);
    padding: 5px 25px;
    border-radius: 0px;
}
.del-btn{
    padding: 5px 27px;
    border-radius: 0px;
    background-color: rgb(210, 0, 0);

}