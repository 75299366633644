#campaign .right-blog-detail {
  padding: 15px;
}

#campaign .share-this-comp span {
  display: inline-block;
  width: 170px;
  font-size: 18px;
  font-weight: 400;
  font-family: "Staatliches", cursive;
  margin: 0 0 20px 0;
  color: #111111;
}
.mesg_input input{
  width: 100%;
}
