.sidebar {
  position: sticky;
  flex: 0 0 auto;
  top: 0;
  left: 0;
  height: 100vh;
  width: 270px;
  background: #3863a0;
  z-index: 105;
  transition: all 0.5s ease;
  overflow: scroll;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.sidebar.close {
  width: 78px;
}
.sidebar .logo-details {
  height: 60px;
  width: inherit;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 100;
  top: 0px;
  background-color: #d5d5d5;
}
.sidebar .logo-details img {
  font-size: 30px;
  color: #0072ce;
  height: 50px;
  min-width: 70px;
  text-align: center;
  line-height: 50px;
  /* width: 50%;
  aspect-ratio: 16/9;
  filter: drop-shadow(1px 2px 2px black); */
  margin: auto;
}
.sidebar .logo-details .logo_name {
  font-size: 22px;
  color: #0072ce;
  font-weight: 600;
  transition: width 0.5s ease;
  /* transition-delay: 0.1s; */
}
.sidebar.close .logo-details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links {
  height: fit-content;
  padding: 20px 0 78px 0;
  overflow: auto;
  top: 60px;
  position: relative;
}
.sidebar.close .nav-links {
  overflow: visible;
}
.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}
.sidebar .nav-links li {
  position: relative;
  list-style: none;
  /* transition: all 0.4s ease; */
  white-space: nowrap;
  font-weight: 600;
  font-size: 15px;
}
.sidebar .nav-links li:hover {
  /* background: #0072ce; */
  background: white;
  color: black;
  border-radius: 40px 5px 40px 5px;
  font-size: 17px;
}

.sidebar .nav-links li .sub-menu li:hover {
  background: #0072ce;
}

.sidebar .nav-links li:hover .icon-link i {
  color: white;
}

.sidebar .nav-links li:hover .link_name {
  /* color: white ; */
  color: black;
}

.sidebar .nav-links li .sub-menu li:hover a,
.sidebar .nav-links li .sub-menu li:hover i {
  color: white;
}

.sidebar .sub-menu li:hover {
  background: none;
  color: #0072ce;
}
.sidebar .nav-links li:hover .icon-link {
  box-shadow: 0px 0px 4px 1px black;
}

.sidebar .nav-links li .icon-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar.close .nav-links li .icon-link {
  display: block;
}
.sidebar .nav-links li i {
  /* height: 50px; */
  min-width: 78px;
  text-align: center;
  line-height: 34px;
  color: #0072ce;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.sidebar .nav-links li.showMenu i.arrow {
  transform: rotate(-180deg);
}
.sidebar.close .nav-links i.arrow {
  display: none;
}
.sidebar .nav-links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
}

.sidebar .nav-links li i {
  color: white;
}
.sidebar .nav-links li .icon-link .link_name {
  font-size: 18px;
  font-weight: 400;
  color: #0072ce;
  transition: all 0.4s ease;
}

.sidebar .nav-links li:hover .icon-link .link_name {
  color: white;
}
.sidebar .nav-links li:hover .i-white {
  /* color: white; */
  color: black;
}
.sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 0px;
  margin-top: 0px;
  background: #fff;
  display: none;
}
.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}
.sidebar .nav-links li .sub-menu a {
  color: #0072ce;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.sidebar .nav-links li .sub-menu a:hover {
  opacity: 1;
}
.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}
.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}
.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}
.sidebar .profile-details {
  position: fixed;
  background-color: #003c73;
  bottom: 0;
  width: 270px;
  display: flex;
  align-items: center;
  padding: 12px 0;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details {
  background: none;
}
.sidebar.close .profile-details {
  width: 78px;
}
.sidebar .profile-details .profile-content {
  display: flex;
  align-items: center;
}
.sidebar .profile-details img {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #1d1b31;
}

.sidebar .profile-details .profile_name,
.sidebar .profile-details .job {
  color: white;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
  transition: all 5s ease;
}
.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job {
  display: none;
}
.sidebar .profile-details .job {
  font-size: 12px;
}
.home-section {
  background: #d5d5d5;
  height: 60px;
  left: 260px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  position: fixed;
  top: 0px;
  z-index: 100;
}
.sidebar.close .home-section {
  left: 78px;
  width: calc(100% - 78px);
}
.home-section .home-content {
  height: 60px;
  display: flex;
  align-items: center;
}
.home-section .home-content .bx-menu,
.home-section .home-content .text {
  font-size: 35px;
  color: white;
}
.home-section .home-content .bx-menu {
  margin: 0 15px;
  cursor: pointer;
  color: #0072ce;
}
.home-section .home-content .text {
  font-size: 23px;
  font-weight: 600;
  /* color: #0072ce; */
  color: black;
}
@media (max-width: 400px) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }
  /* .sidebar {
    width: 78px;
  } */
  .sidebar.close {
    width: 0;
  }
  .home-section {
    /* left: 78px;
    width: calc(100% - 78px); */
    z-index: 100;
  }
  .sidebar.close ~ .home-section {
    width: 100%;
    left: 0;
  }
}
.sidenav-menu-heading {
  padding: 0.75rem 1rem 0.75rem;
  font-size: 0.7rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #ffffff;
  align-items: center;
  gap: 2em;
  display: inline-flex;
}

.sidenav-menu-heading span {
  height: 1px;
  display: block;
  width: 44px;
  position: relative;
  background-color: white;
}
.sidenav-menu-heading p {
  margin: 0px;
}
