.overview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: space-between;
  align-items: center;
  width: inherit;
}

.overview-container .table-responsive {
  width: 100%;
}

.overview-container table {
  margin: 0px;
}

.overview-container table tr {
  height: 2em;
}

.overview-container .card-title {
  font-size: inherit;
}

.overview-container .overview-header {
  width: 100%;
}
.overview-container div h3 {
  border-bottom: 4px solid #0072ce;
}

.overview-container:last-child {
  margin-bottom: 4em;
}

#chart-container {
  min-width: 310px;
  height: 100%;
  max-width: 100%;
  flex: 1;
}

#chart-container-users {
  min-width: 310px;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
  flex: 1;
}

.storage {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  margin-top: 20px;
  box-shadow: 0px 0px 6px #0072ce;
}
.storage section {
  background: white;
  width: 350px;
  padding-bottom: 1em;
}

.storage section:first-child {
  border-radius: 20px 20px 0px 0px;
}

.storage section:last-child {
  border-radius: 0px 0px 20px 20px;
}

.storage section header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  padding: 25px;
}
.storage section header h4 {
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins";
  color: #000;
  margin: 0;
}
.storage section header button {
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins";
  color: #e2daed;
  padding: 5px 10px;
  background: transparent;
  border: 1px solid #e2daed;
  border-radius: 20px;
}
.details footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
}
.details footer .chart {
  padding: 25px;
}
.details footer .text {
  margin-left: 30px;
  padding: 25px 0;
}
.details footer .text div {
  position: relative;
}
.details footer .text div h6 {
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins";
  color: #000;
  margin: 0;
}
.details footer .text div p {
  font-size: 14px;
  font-weight: 300;
  font-family: "Poppins";
  color: #d3d3d3;
  margin: 0;
  margin-bottom: 20px;
}
.details footer .text div span {
  top: 5px;
  left: -25px;
  position: absolute;
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

#media h6 > span {
  background: #dec1f2;
}
#document h6 > span {
  background: #f9d89c;
}
#apps h6 > span {
  background: #ffc0ca;
}

#my-cloud footer {
  padding: 0px 25px;
}

#my-cloud h4 {
  padding: 8px;
  padding-bottom: 0px;
  text-align: center;
}

#my-cloud footer > .chart :last-child {
  width: 70%;
  background: #f87e85;
}
#my-cloud footer .stat {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  font-size: 13px;
  font-weight: 400;
  font-family: "Poppins";
  color: #abb1ba;
}
#my-cloud footer .chart {
  /* margin-top: 10px; */
  position: relative;
  filter: none;
}
#my-cloud footer .chart div {
  height: 12px;
  border-radius: 6px;
}
#my-cloud footer .chart :first-child {
  width: 100%;
  background: #f5f2f5;
}
#my-cloud footer .chart :last-child {
  position: absolute;
  top: 0;
  left: 0;
}

.overview-header .status-card {
  width: 300px;
}

.filter-inputs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2em;
  gap: 2em;
}

#date-picker {
  /* display: flex;
  justify-content: space-between; */
  /* margin: 1em; */
  /* position: fixed; */
  /* z-index: 20; */
  /* right: 0px; */
  /* border: 1px solid black; */
  align-self: center;
  background: #5bb6ff;
  padding: 0.1em;
  /* margin-right: 0px; */
  width: fit-content;
  /* border-radius: 1em 1em 1em 1em; */
  filter: drop-shadow(0px 0px 5px #0072ce);
}

#overlay-date {
  opacity: 1;
  background-color: aliceblue;
}

#overlay-date button {
  color: initial;
}

#overlay-date .tooltip-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.dimension-inputs {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.dimension-inputs p {
  color: white;
}

.dimension-inputs .status-card {
  width: auto;
  cursor: pointer;
}

.dimensions {
  display: flex;
  margin: 2em;
  gap: 3em;
  flex-direction: row;
  align-items: center;
  /* height: 350px; */
  background-color: #3863a0;
  box-shadow: 0px 0px 9px #0072ce;
  border-radius: 1em;
  padding: 1em;
  width: 100%;
  justify-content: space-between;
}

.dimension-dates {
  text-align: center;
}

.dimension-dates p {
  font-family: monospace;
  margin: 0px;
  color: white;
}

.overview-charts {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.chart {
  filter: drop-shadow(0px 0px 3px #0072ce);
}

.analytics-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  margin: 1em;
  width: inherit;
}

.analytics-card-container .card {
  border: none;
  min-width: 17em;
  border-radius: 0px;
}

.analytics-card-container .card h3 {
  text-align: end;
  color: white;
  font-size: 2rem;
}

.analytics-card-container .card:hover {
  box-shadow: 5px 5px 8px gray;
}

.table_hover {
  font-size: 1em;
}
.table_hover:hover {
  background-color: #007adf;
  color: white;
  width: 201px;
  font-size: 1.1em;
}

.table-head {
  background-color: #3863a0 !important;
  color: white;
}
.noData_wrapper {
  display: flex;
  padding: 200px 0px;
  justify-content: center;
}
.noData_wrapper h2 {
  color: rgb(184, 184, 184);
  font-family: "Open Sans", sans-serif;
}
.dashboard_title {
  color: rgb(0, 58, 99);
  border-bottom: 5px solid #3863a0;
  margin: 0px 340px 20px 0px;
}
@media (max-width: 950px) {
  .dashboard_title {
    margin: 0px 85px 20px 0px;
  }
}

.data-grid-container {
  margin: 1em 0em;
  max-width: 75vw;
}

.grid-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.grid-export {
  gap: 1em;
  display: flex;
}

.grid-export-btn {
  padding: 0px 10px;
}

.grid-export-btn i {
  padding-right: 10px;
}

.filter-container-table {
  line-height: 35px;
  padding: 0;
}

.filter-container-table > div {
  padding-block: 0;
  padding-inline: 8px;
}

.filter-container-table > div:first-child {
  border-block-end: 1px solid var(--rdg-border-color);
}

.filter-input-table {
  inline-size: 100%;
  padding: 4px;
  font-size: 14px;
}

.filter-cell {
  line-height: 35px;
  padding: 0;
}
