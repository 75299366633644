.upcomingShow{
    border-radius: 5px;
}
.upcoming_content{
    padding: 10px;
    background-color: white;
    text-align: center;
}
.upcomingEdit{
    padding: 10px 40px 10px 40px;
    background-color: rgba(0, 0, 0, 0.133);
    border-radius: 5px;
}
.upcomingEdit input{
    width: 100%;
    border: none;
    font-size: 1.2em;
    text-align: center;
    padding: 6px;
}