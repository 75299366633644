.img-small {
  width: 60px;
}

.box-one {
  border: 1px solid #ccc;

  box-shadow: 0 0.2px 2px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 8px;
}

.editor-container {
  background-color: aliceblue;
  max-width: inherit;
  width: 100%;
  padding-bottom: 20px;
}
.box-two {
  /* background-color: aqua; */
}

.sub-heading {
  font-size: 18px;
}

/* image upload */
.image-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border: 3px dashed #ccc;
  padding: 20px;
  border-radius: 12px;
}

.upload-label {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.upload-icon {
  margin-right: 40px;
}

.upload-icon img {
  width: 100px;
  height: 100px;
}

.upload-text {
  font-size: 16px;
  overflow-wrap: anywhere;
}

.selected-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-uploader img {
  width: 30px;
  height: auto;
}

.left-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading {
  font-size: 1.7rem;
}

.preview-button img {
  width: 20px;
}

.preview-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #f0f0f0;
  color: #333;
  font-size: 16px;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.preview-button:hover {
  background-color: #ddd;
}

.preview-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.save-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: var(--primary_color);
  color: #fff;
  font-size: 16px;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-grp {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
}

.BlogsContainer {
  margin-bottom: 3em;
}

.box-two button {
  /* background-color: #f0f0f0; */
  color: black;
  font-weight: lighter;
}

.box-one button {
  background-color: #f0f0f0;
  color: black;
  font-weight: lighter;
}

.resource-top {
  display: flex;
  flex-wrap: wrap;
}

.resource-inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5em;
}
/* docs Preview */
.preview_image_wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}