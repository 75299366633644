.add_image{
    position: relative;
}
.add_image button{
    cursor: pointer;
}
.add_image input{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 50px;
    opacity: 0;
    cursor: pointer;
}
.top-image{
    width: 300px;
}
.table-content{
    display: inline-block;
    position: relative;
}
.table-data{
    text-align: center;
    vertical-align: middle;
}