.box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
}

.box {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  position: relative;
}

.box:hover {
  transform: translateY(-5px);
}

.serial-number {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #333;
  color: #fff;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 50%;
}

.image {
  max-width: 100%;
  height: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.icon-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.edit-icon,
.delete-icon {
  font-size: 18px;
  color: #555;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.edit-icon:hover,
.delete-icon:hover {
  color: #333;
}

.banner-images-container img {
  width: 300px;
}

.navbar-slider-container {
  margin-bottom: 2rem;
}

.navbar-slider-container .item-container {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1rem;
  margin: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 250px;
}

.navbar-slider-container .title-input {
  padding: 0.5rem;
  font-size: 1rem;
  width: 100%;
}

.navbar-slider-container .item-title {
  margin: 0;
  font-size: 1.25rem;
}

.navbar-slider-container .list-container {
  list-style-type: none;
  padding: 0;
  margin-top: 1rem;
}

.navbar-slider-container .list-item {
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: #f7f7f7;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-slider-container .list-input {
  width: 100%;
  font-size: 1rem;
}

.home-page-editable .actions {
  width: 100%;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 6px;
}

.home-page-editable .actions .lx-btn {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: white;
}

.home-page-editable .actions .lx-btn#cancel,
.home-page-editable .actions .lx-btn.cancel {
  background-color: #f55;
}

.home-page-editable .actions .lx-btn#clear,
.home-page-editable .actions .lx-btn.clear {
  color: black;
  background-color: white;
}

.home-page-editable .actions .lx-btn#save,
.home-page-editable .actions .lx-btn.save {
  background-color: #0072ce;
}

.home-page-editable .edit-btn button {
  background-color: #495057;
}

.what-we-do {
  padding-top: 50px;
}

.what-we-do a {
  padding: 10px 35px;
}

.warld-help {
  font-size: 42px;
  color: #88bde8;
  line-height: 40px;
}

.netwark-food {
  line-height: 20px;
}

.btn-pink {
  background: #ec2d8b;
  color: #fff;
  display: inline-block;
  font-family: "Staatliches", cursive;
  font-size: 18px;
}

.btn-pink:hover {
  background: black;
  color: white;
}

.we-do-bx {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}

.we-do-bx h4 {
  position: absolute;
  z-index: 99;
  bottom: 20px;
  margin: auto;
  left: 0;
  right: 0;
  padding: 15px 10px;
  text-align: center;
  font-size: 24px;
  background: url(../../public/img/line-highter.png) center no-repeat;
}

.we-do-bx a {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.we-do-bx a:hover {
  color: #1c93f4;
}

#banner-images {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

#banner-images li {
  display: flex;
  justify-content: space-around;
  box-shadow: 0px 0px 2px 0px;
}

.we-do-bx .image-container {
  position: relative;
  display: inline-block;
}

.we-do-bx .edited-image,
.we-do-bx .default-image {
  max-width: 100%;
  height: auto;
  border: 2px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.we-do-bx .image-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.we-do-bx .image-title {
  margin-top: 10px;
}

.we-do-bx .edit-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.we-do-bx .image-container {
  width: 400px;
  height: auto;
}
.img-vid-cont .video-container {
  margin-top: 20px;
}

.img-vid-cont video {
  width: 100%;
  height: auto;
  display: block;
}
.video-react .video-react-big-play-button {
  top: 50% !important;
  left: 45% !important;
}

.feature {
  height: 150px;
  aspect-ratio: 1;
  display: flex;
  box-shadow: 0px 0px 2px 0px;
}
