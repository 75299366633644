.gallery_wrapper{
    margin-bottom: 2rem;
}
.img_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}
.title_event_preview{
    text-align: center;
}
.description_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}