#card-preview .card-body h4 a:hover,
#card-preview .card-body h3 a:hover {
  color: #1c93f4;
}

#card-preview .card-body p {
  line-height: 18px;
  font-size: 14px;
}

#card-preview .card-body h3 {
  line-height: 22px;
}

#card-preview .posted-date {
  color: #999;
  font-size: 14px;
  margin-bottom: 10px;
}

#card-preview .posted-date span {
  padding: 0px 15px;
  font-size: 14px;
  color: #999;
  line-height: 20px;
}

#card-preview .panel-youth {
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 0;
}
#card-preview .panel-youth a {
  font-weight: 400;
  font-family: "Staatliches", cursive;
}
#card-preview .rural-indastr span {
  font-weight: bold;
  margin-left: 5px;
  color: #000;
}

#card-preview .rural-indastr {
  padding: 10px 0;
  color: #999;
  font-size: 14px;
}

#card-preview .Instructor {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ebebeb;
  /* border-bottom: 1px solid #ebebeb; */
  margin: 0;
  padding: 10px 0px;
}

#card-preview .Instructor p span {
  font-weight: bold;
  color: #000;
}

#card-preview .Instructor p {
  margin: 0;
  padding: 0;
  line-height: 18px;
  font-size: 14px;
  color: #999;
}

#card-preview .btn-pink {
  background: #ec2d8b;
  color: #fff;
  display: inline-block;
  font-family: "Staatliches", cursive;
  font-size: 18px;
}

#card-preview .btn-pink:hover {
  background: black;
  color: white;
}

#card-preview .shadow-sm {
  margin: 10px 0px;
}

#card-preview .card {
  width: 300px;
  margin: 0.5em;
}

#card-preview img {
  max-width: 100%;
  height: 200px;
  margin: auto;
}

#card-preview a {
  color: #0d6efd;
}

#card-preview .card-footer {
  flex: 1 1 auto;
  padding: 0rem 1rem;
  border: none;
  background: initial;
}
