.rbc-calendar {
  height: 100vh;
}

.rbc-toolbar .rbc-toolbar-label {
  font-size: 1.2rem;
  font-weight: 700;
}

.rbc-button-link {
  margin: 5px;
}

.badge {
  display: inline-flex;
  align-items: center;
  margin-right: 1em;
}

.badge p {
  margin: 0px;
}

#calendar-legend .badge {
  background-color: #3174ad !important;
}
.upcoing_wrapper{
  margin-top: 80px;
  margin-bottom: 80px;
  background-color:#cbe0f1 ;
  padding: 20px 20px 30px 20px;
}
.upcoming_events{
  margin-bottom: 80px;
}
.upcoming_events h3{
  text-align: center;
  border-radius: 20px;
  color: black;
  padding: 5px;
}
.upcoming_webinar{
  margin-bottom: 80px;
}
.upcoming_webinar h3{
  text-align: center;
  border-radius: 20px;
  color: black;
  padding: 5px;
}
.upcoming_events_webinar{
  text-align: center;
  border-radius: 20px;
  color: black;
  padding: 5px;
}